import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import ApiWrapper from './../../utils/api-wrapper';
import Modal from './../../components/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import MoneyInput from '../../components/money-input';
import Switch from "react-switch";

export default inject("data")(withRouter(observer(class AdminSuppliers extends Component {
    nameOrder = 0;
    supplierListInitial = null;
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            successMessage: null,
            supplier: null,
            searchStr: null,
            id_del_supplier: null,
            showImportModal: false,
            showBatchDelete: false,
            suppliers: [],
            importFile: null,
        }
    }

    componentDidMount() {
        ApiWrapper.fetch(this, "/clients", "GET", {},
            response => {
                this.setState({
                    clients: response.data
                }, () => {
                    this.refreshSuppliers();
                })
            })
    }

    refreshSuppliers() {
        ApiWrapper.fetch(this, "/suppliers", "GET", {},
            response => {
                let suppliers = response.data;

                suppliers.forEach(sup => {
                    if (sup.id_client != null) {
                        this.state.clients.forEach(c => {
                            if (c._id === sup.id_client) {
                                sup.client_name = c.name;
                            }
                        })
                    }
                })

                this.setState({
                    supplier: null,
                    suppliers: suppliers,
                    supplierListInitial: suppliers
                })
            })
    }

    showImport() {
        this.setState({
            showImportModal: true,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let data = new FormData();
        data.append("names", JSON.stringify(this.state.supplier.names));
        data.append("zipcode", this.state.supplier.zipcode);
        data.append("budget", this.state.supplier.budget);
        data.append("active", this.state.supplier.active);
        data.append("country", this.state.supplier.country);
        data.append("city", this.state.supplier.city);
        data.append("state", this.state.supplier.state);
        data.append("address", this.state.supplier.address);
        data.append("gl_code", this.state.supplier.gl_code);
        data.append("supplier_number", this.state.supplier.supplier_number);
        data.append("need_moderation", this.state.supplier.need_moderation);

        if (this.props.data.config.type === "legal") {
            data.append("is_billable", this.state.supplier.IsBillable);
            data.append("tax_code", this.state.supplier.TaxCode);
            data.append("cost_type", this.state.supplier.cost_type);
        }

        ApiWrapper.fetch(this, "/suppliers" + (this.state.supplier._id != null ? "/" + this.state.supplier._id : ""), (this.state.supplier._id != null ? "PUT" : "POST"), data,
            response => {
                this.setState({
                    suppler: null,
                    successMessage: "Sauvegarde complétée"
                }, () => {
                    this.refreshSuppliers();
                })
            },
            response => {
                this.setState({
                    errorMessage: "Erreur lors de la sauvegarde"
                })
            })
    }

    updateField(fieldName, value, subField = null) {
        let supplier = this.state.supplier;

        if (subField == null) {
            supplier[fieldName] = value;
        }
        else {
            supplier[fieldName][subField] = value;
        }

        this.setState({
            supplier: supplier
        })
    }

    processDelete() {
        ApiWrapper.fetch(this, "/suppliers/" + this.state.id_del_supplier, "DELETE", {},
            response => {
                this.setState({
                    id_del_supplier: null
                }, () => {
                    this.refreshSuppliers();
                })
            })
    }

    processBatchDelete() {
        let count = 0;

        document.querySelectorAll(".supplier-checkbox").forEach(elem => {
            if (elem.checked) {
                count++;
                ApiWrapper.fetch(this, "/suppliers/" + elem.value, "DELETE", {},
                    response => {
                        count--;

                        if (count === 0) {
                            this.setState({ showBatchDelete: false });
                            this.setState({ actionType: null }, () => this.refreshSuppliers());
                        }
                    },
                    response => {
                        count--;

                        if (count === 0) {
                            this.setState({ showBatchDelete: false });
                            this.setState({ actionType: null }, () => this.refreshSuppliers());
                        }
                    }
                )
            }
        });
    }

    addSupplier() {
        this.setState({
            supplier: {
                names: [""],
                zipcode: "",
                country: "",
                active: true,
                need_moderation: false,
                TaxCode: "T_IN",
                IsBillable: true
            }
        })
    }

    addName() {
        let supplier = this.state.supplier;
        supplier.names.push("");

        this.setState({
            supplier: supplier
        })
    }

    updateName(idx, value) {
        let supplier = this.state.supplier;
        supplier.names[idx] = value;

        this.setState({
            supplier: supplier
        })
    }

    deleteName(idx) {
        let supplier = this.state.supplier;
        supplier.names.splice(idx, 1);

        this.setState({
            supplier: supplier
        })
    }

    prepareImport(f) {
        if (f.length > 0) {
            this.setState({
                importFile: f[0],
            })
        }
    }


    toggleSelectAll(checkbox) {
        document.querySelectorAll(".supplier-checkbox").forEach(elem => {
            if (checkbox.checked) {
                elem.checked = true;
            }
            else {
                elem.checked = false;
            }
        });

        this.updateActionButtons();
    }

    updateActionButtons() {
        let count = 0;
        let notSelectedCount = 0;

        document.querySelectorAll(".supplier-checkbox").forEach(elem => {
            if (elem.checked) {
                count++;
            }
            else {
                notSelectedCount++;
            }
        });

        document.querySelectorAll(".action-button").forEach(elem => {
            if (count > 0) {
                elem.classList.remove("faded")
            }
            else {
                elem.classList.add("faded")
            }
        });

        if (notSelectedCount > 0) {
            document.querySelector("#all-selector-checkbox").checked = false;
        }
    }

    processImport() {
        if (this.state.importFile != null) {
            const data = new FormData();
            data.append("csv", this.state.importFile);

            ApiWrapper.fetch(this, "/suppliers/import", "POST", data,
                response => {
                    this.setState({
                        showImportModal: false,
                    }, () => {
                        if (response.data.message.length > 0) {
                            this.setState({
                                errorMessage: JSON.stringify(response.data.message),
                            }, () => {
                                this.refreshSuppliers();
                            })
                        }
                        else {
                            this.refreshSuppliers();
                        }
                    })
                },
                null, true)
        }
    }

    /**
     * we search from the initial suppliers list we received from the api
     * 
     */
    search() {
        if (this.supplierListInitial == null) {
            this.supplierListInitial = this.state.suppliers;
        }
        this.setState({ suppliers: this.supplierListInitial.filter(sup => sup.names[0] !== null && sup.names[0].toLowerCase().includes(this.state.searchStr.toLowerCase())) });
    }


    /**
     * sorting by name order depends on nameOrder variable
     * 1 for asc
     * -1 for desc
     * 0 not ordered
     */
    sortByName() {

        if (this.nameOrder === 0) {
            this.nameOrder = 1;
        }

        this.setState({ suppliers: this.state.suppliers.sort((a, b) => a.names[0] > b.names[0] ? this.nameOrder : - this.nameOrder) })

        if (this.supplierListInitial !== null) {
            this.supplierListInitial.sort((a, b) => a.names[0] > b.names[0] ? this.nameOrder : - this.nameOrder);
        }

        this.nameOrder = - this.nameOrder;
    }

    render() {
        return <div>
            {
                this.state.errorMessage != null ?
                    <Modal title="Attention" okText={"OK"} okAction={() => this.setState({ errorMessage: null })}>
                        <p className='text-danger'>{this.state.errorMessage}</p>
                    </Modal>
                    :
                    this.state.successMessage != null ?
                        <Modal title="Attention" okText={"OK"} okAction={() => this.setState({ successMessage: null })}>
                            {this.state.successMessage}
                        </Modal>
                        :
                        this.state.id_del_supplier != null ?
                            <Modal title="Confirmation" cancelAction={() => this.setState({ id_del_supplier: null })} okAction={() => this.processDelete()} okText={this.props.data.t.read("delete")} cancelText={this.props.data.t.read("cancel")}>
                                {this.props.data.t.read("supplier_table.delete_supplier")}
                            </Modal>
                            :
                            this.state.showBatchDelete ?
                                <Modal title="Confirmation" cancelAction={() => this.setState({ showBatchDelete: false })} okAction={() => this.processBatchDelete()} okText={this.props.data.t.read("delete")} cancelText={this.props.data.t.read("cancel")}>
                                    {this.props.data.t.read("supplier_table.delete_suppliers")}
                                </Modal>
                                :
                                this.state.showImportModal ?
                                    <Modal width="50vw" title={this.props.data.t.read("importation")} cancelAction={() => this.setState({ showImportModal: false })} okAction={() => this.processImport()} okText={this.props.data.t.read("import")} cancelText={this.props.data.t.read("cancel")}>
                                        <div>
                                            {this.props.data.t.read("supplier_table.csv_file_format")}
                                            <div className='card p-2'>
                                                {this.props.data.t.read("supplier_table.supplier_details_string")}<br />
                                                {this.props.data.t.read("supplier_table.supplier_details_string")}<br />
                                                {this.props.data.t.read("supplier_table.supplier_details_string")}<br />
                                            </div>
                                            <div className='mt-2'>
                                                <input className='form-control' type="file" id="import-csv-file" onChange={f => this.prepareImport(f.target.files)} />
                                            </div>
                                        </div>
                                    </Modal>
                                    :
                                    this.state.supplier != null ?
                                        <form method="post" onSubmit={(e) => { this.handleSubmit(e) }}>
                                            <Modal width="60vw" title={this.props.data.t.read("supplier_table.supplier_management")} okIsSubmit={true} cancelAction={() => this.setState({ supplier: null })} okText={this.props.data.t.read("save")} cancelText={this.props.data.t.read("cancel")}>
                                                <h5>
                                                    {this.props.data.t.read("supplier_table.names")}
                                                    <button className='btn btn-info btn-xs ml-2' type="button" onClick={() => this.addName()}>+</button>
                                                </h5>
                                                {
                                                    this.state.supplier.names.map((n, i) => {
                                                        return <div className='mb-2' key={i}>
                                                            <div className='row'>
                                                                <div className='col-11'>
                                                                    <input required placeholder={"Nom"} className='form-control' type="text" value={n} onChange={e => this.updateName(i, e.target.value)} />
                                                                </div>
                                                                {
                                                                    this.state.supplier.names.length > 1 ?
                                                                        <div className='col-1'>
                                                                            <button className='btn btn-danger btn-sm ml-2' type="button" onClick={() => this.deleteName(i)}>X</button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                                <h5 className='mt-4'>
                                                    Codes
                                                </h5>
                                                <div className='mt-1'>
                                                    <input placeholder={this.props.data.t.read("gl_codes")} className='form-control' type="text" value={this.state.supplier.gl_code ?? ""} onChange={e => this.updateField("gl_code", e.target.value)} />
                                                </div>

                                                { this.props.data.config.type === "legal" && <div className='mt-1'>
                                                    <input placeholder={this.props.data.t.read("supplier_table.cost_type")} className='form-control' type="text" value={this.state.supplier.cost_type ? this.state.supplier.cost_type : ""} onChange={e => this.updateField("cost_type", e.target.value)} />
                                                </div>}
                                                
                                                <div className='mt-1'>
                                                    <input readOnly={this.props.data.config.engine === "juris"} required={this.props.data.config.engine !== "juris"}  placeholder={this.props.data.t.read("supplier_table.supplier_number")} className='form-control' type="text" value={this.state.supplier.supplier_number ?? ""} onChange={e => this.updateField("supplier_number", e.target.value)} />
                                                </div>
                                                <div>
                                                    <div className='mt-1'>
                                                        <h5 className='mt-4'>Budget</h5>
                                                        <div>
                                                            <MoneyInput value={this.state.supplier.budget} onChange={val => this.updateField("budget", val)} locale={`${this.props.data.t.lang}-${this.props.data.t.lang.toUpperCase()}`} placeholder="budget" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <h5 className='mt-4'>
                                                    {this.props.data.t.read("supplier_table.other_information")}
                                                </h5>
                                                <div className='mt-1'>
                                                    <input required placeholder={this.props.data.t.read("supplier_table.address")} className='form-control' type="text" value={this.state.supplier.address ?? ""} onChange={e => this.updateField("address", e.target.value)} />
                                                </div>
                                                <div className='mt-1'>
                                                </div>
                                                <div className='mt-1 d-flex justify-content-between'>
                                                    <input required placeholder={this.props.data.t.read("supplier_table.postal_code_zip")} className='form-control' type="text" value={this.state.supplier.zipcode ?? ""} onChange={e => this.updateField("zipcode", e.target.value)} />
                                                    <input required placeholder={this.props.data.t.read("country")} className='form-control' type="text" value={this.state.supplier.country ?? ""} onChange={e => this.updateField("country", e.target.value)} />
                                                    <input required={this.props.data.config.engine === "juris"} placeholder={this.props.data.t.read("state")} className='form-control' type="text" value={this.state.supplier.state ?? ""} onChange={e => this.updateField("state", e.target.value)} />
                                                    <input required={this.props.data.config.engine === "juris"} placeholder={this.props.data.t.read("city")} className='form-control' type="text" value={this.state.supplier.city ?? ""} onChange={e => this.updateField("city", e.target.value)} />
                                                </div>
                                                {
                                                    this.props.data.config.type === "legal" ?
                                                        <>
                                                            <div className='mt-4'>
                                                                <h5>{this.props.data.t.read("supplier.tax_code")}</h5>
                                                                <select value={this.state.supplier.TaxCode ?? "T_IN"} placeholder={this.props.data.t.read("supplier.tax_code")} className='form-control' onChange={e => this.updateField("TaxCode", e.target.value)}>
                                                                    <option value="N_IN">{this.props.data.t.read("supplier.choices.tax_code.n_in")}</option>
                                                                    <option value="T_IN">{this.props.data.t.read("supplier.choices.tax_code.t_in")}</option>
                                                                </select>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <h5>{this.props.data.t.read("supplier.is_billable")}?</h5>
                                                                <select value={this.state.supplier.IsBillable ?? true} placeholder={this.props.data.t.read("supplier.is_billable")} className='form-control' onChange={e => this.updateField("IsBillable", e.target.value)}>
                                                                    <option value={true}>{this.props.data.t.read("supplier.choices.is_billable.true")}</option>
                                                                    <option value={false}>{this.props.data.t.read("supplier.choices.is_billable.false")}</option>
                                                                </select>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                <div className='mt-4 ml-2'>
                                                    <label>
                                                        <input type="checkbox" defaultChecked={this.state.supplier.need_moderation} onClick={() => this.updateField("need_moderation", !this.state.supplier.need_moderation)} /> {this.props.data.t.read("supplier_table.needs_moderation")}
                                                    </label>
                                                </div>
                                                <div className='d-flex mt-3'>
                                                    <div className='col-2 mt-2'>{this.props.data.t.read("supplier_table.active")}</div>
                                                    <div className='col-3'>
                                                        <Switch className='switch'
                                                            checked={this.state.supplier.active}
                                                            width={70}
                                                            onColor="#F39800"
                                                            onHandleColor="#2693e6"
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            onChange={active => this.updateField("active", active)}
                                                        />
                                                    </div>
                                                </div>
                                            </Modal>
                                        </form>
                                        :
                                        null
            }
            <h2>
                <input type="text" className="form-control search-field" placeholder={this.props.data.t.read("search")} onChange={e => this.setState({ searchStr: e.target.value })} onKeyUp={() => this.search()} />
                {this.props.data.t.read("supplier_table.supplier_management")} ({this.state.suppliers.length})
                <button className='btn btn-primary btn-sm float-right' onClick={() => this.addSupplier()}>{this.props.data.t.read("add")}</button>
                <button className='btn btn-info btn-sm float-right mr-2' onClick={() => this.showImport()}>{this.props.data.t.read("import")}</button>
                <button className="btn btn-danger btn-sm float-right faded action-button mr-2" onClick={() => this.setState({ showBatchDelete: true })} >{this.props.data.t.read("delete")}</button>

            </h2>
            <table className='table table-striped table-sm font-xsmall'>
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" id="all-selector-checkbox" onChange={e => this.toggleSelectAll(e.target)} />
                        </th>
                        <th onClick={() => this.sortByName()}>{this.props.data.t.read("name")} {this.nameOrder !== 0 && <FontAwesomeIcon icon={this.nameOrder === 1 ? faArrowDown : faArrowUp} />} </th>
                        <th>{this.props.data.t.read("supplier_table.postal_code")} {this.rotation}</th>
                        <th>{this.props.data.t.read("country")}</th>
                        <th>{this.props.data.t.read("supplier_table.created_by")}</th>
                        <th>{this.props.data.t.read("supplier_table.created_on")}</th>
                        <th>{this.props.data.t.read("supplier_table.last_modified_on")}</th>
                        <th className='text-center'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.suppliers.map(supplier => {
                            return <tr key={supplier._id} className={supplier.need_moderation ? "bg-info" : ""}>
                                <td onClick={e => e.stopPropagation()}>
                                    <input type="checkbox" className="supplier-checkbox" value={supplier._id} onChange={() => this.updateActionButtons()} />
                                </td>
                                <td>{supplier.names[0]}</td>
                                <td>{supplier.zipcode}</td>
                                <td>{supplier.country}</td>
                                <td>{supplier.client_name ?? "Xpens"}</td>
                                <td>{supplier.created_at}</td>
                                <td>{supplier.updated_at}</td>
                                <td className='text-center'>
                                    <button className='btn btn-primary btn-sm' onClick={() => this.setState({ supplier: JSON.parse(JSON.stringify(supplier)) })}>{this.props.data.t.read("supplier_table.edit")}</button>
                                    <button className='ml-1 btn btn-danger btn-sm' onClick={() => this.setState({ id_del_supplier: supplier._id })}>{this.props.data.t.read("supplier_table.delete")}</button>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    }
})));