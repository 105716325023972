import React, { useEffect, useState } from "react";
import ApiWrapper from "../../utils/api-wrapper";
import { withRouter } from "react-router-dom";
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight, faChevronLeft, faChevronRight, faSpinner, faWindowClose, faCog} from "@fortawesome/free-solid-svg-icons";
import Modal from '../../components/modal';
import Switch from "react-switch";
import Select from 'react-select';


const AdminUsers = ({ data }) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(null);
    const [clients, setClients] = useState(null);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filterValue, setFilterValue] = useState("");
    const [filterType, setFilterType] = useState("search");
    const [newUser, setNewUser] = useState({email: "", first_name: "", last_name: "", role: "Admin",});
    const [showAddUserConfirm, setShowAddUserConfirm] = useState(false);
    const [showDeleteUserConfirm, setShowDeleteUserConfirm] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [showSubmitChangesConfirm, setShowSubmitChangesConfirm] = useState(false);
    const [showResetPasswordConfirm, setShowResetPasswordConfirm] = useState(false);
    const [userToResetPassword, setUserToResetPassword] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    useEffect(() => {
        refreshClients();
    }, []);

    useEffect(() => {
        if (client) {
            setPage(1);
            setFilterType("email");
            setFilterValue("");
            refreshUsers(client);
        }
    }, [client]);

    const refreshClients = () => {
        ApiWrapper.fetch(this, "/clients", "GET", {},
            response => {
                const clients = response.data;
                setClients(clients);

                if (data.role === "Admin") {
                    if (clients.length >= 1) {
                        // Admins should only have one client
                        setClient(clients[0]);
                        refreshUsers(clients[0]);
                    } else {
                        // Handle the case where no clients are returned
                        setClient(null);
                        setUsers([]);
                    }
                } else if (data.role === "SuperAdmin") {
                    // SuperAdmins can select a client
                    if (client) {
                        const updatedClient = clients.find(c => c._id === client._id);
                        if (updatedClient) {
                            setClient(updatedClient);
                            refreshUsers(updatedClient);
                        } else {
                            setClient(null);
                            setUsers([]);
                        }
                    } else {
                        setClient(null);
                        setUsers([]);
                    }
                }

                setLoading(false);
            })
    }

    const selectClient = (client) => {
        setClient(JSON.parse(JSON.stringify(client)))
        setPage(1)
        setFilterType("email");
        setFilterValue("");
        refreshUsers(client);
    }

    const refreshUsers = (selectedClient = client) => {
        const filters = [
            { id: "id_client", value: selectedClient._id },
        ];
        const sorting = [{ id: "first_name", desc: false }, { id: "last_name", desc: false }];
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;
        ApiWrapper.fetch(this, `/users/${searchData}`, "GET", {},
            response => {
            let fetchedUsers = response.data.users
            setUsers(JSON.parse(JSON.stringify(fetchedUsers)));
            setLoading(false)
            });
    }

    const updateUser = (updatedUser) => {
        const updatedUsers = [...users];
        const index = updatedUsers.findIndex(user => user._id === updatedUser._id);
        updatedUsers[index] = updatedUser;
        setUsers(updatedUsers)
        setIsDirty(true);
    }

    const confirmDeleteUser = (user) => {
        setUserToDelete(user);
        setShowDeleteUserConfirm(true);
    };

    const deleteUser = () => {
        updateUser({...userToDelete, is_deleted: true});
        setShowDeleteUserConfirm(false);
        setUserToDelete(null);
    };

    const cancelDeleteUser = () => {
        setShowDeleteUserConfirm(false);
        setUserToDelete(null);
    };

    const userCanModify = (user) => {
        return client.config.roles.indexOf(user.role) >= client.config.roles.indexOf(data.role);
    }

    const availableRoles = (user) => {
        let id = client.config.roles.indexOf(data.role);
        if (!userCanModify(user)) id = 0;

        let roles = client.config.roles;
        let returnRoles = [];

        for (let i = id; i < roles.length; i++)
            returnRoles.push(roles[i]);

        return returnRoles;
    }

    const generatePassword = (user) => {
        setUserToResetPassword(user);
        setShowResetPasswordConfirm(true);
    };

    const confirmResetPassword = () => {
        updateUser({ ...userToResetPassword, is_generating: true });

        const formData = new FormData();
        formData.append("user", JSON.stringify(userToResetPassword));

        ApiWrapper.fetch(
            this,
            "/users/generate_password",
            "PUT",
            formData,
            (response) => {
                setSuccessMessage(
                    `${userToResetPassword.first_name} ${userToResetPassword.last_name}\n`+ data.t.read("password_reset_successful")
                );
                updateUser( { ...userToResetPassword, is_generating: false });
                // Reset the modal state
                setShowResetPasswordConfirm(false);
                setUserToResetPassword(null);
            },
            (response) => {
                setErrorMessage(
                    `${userToResetPassword.first_name} ${userToResetPassword.last_name}\n`+ data.t.read("password_reset_failed")
                );
                updateUser({ ...userToResetPassword, is_generating: false });
                // Reset the modal state
                setShowResetPasswordConfirm(false);
                setUserToResetPassword(null);
            }
        );
    };

    const cancelResetPassword = () => {
        setShowResetPasswordConfirm(false);
        setUserToResetPassword(null);
    };



    const submitChanges = () => {
        setShowSubmitChangesConfirm(true);
    }

    const confirmSubmitChanges = () => {
        setShowSubmitChangesConfirm(false);
        setLoading(true);
        let formData = new FormData();
        formData.append("id", client._id ?? "");
        formData.append("name", client.name);
        formData.append("emails", client.emails ?? "");
        formData.append("config", JSON.stringify(client.config));
        formData.append("supplier_codes", JSON.stringify(client.supplier_codes));
        formData.append("supplier_units", JSON.stringify(client.supplier_units));
        formData.append("users", JSON.stringify(users));

        ApiWrapper.fetch(this, "/clients", "POST", formData,
            response => {
                setErrorMessage(data.t.read("save_completed"));

                // Refresh clients and users without resetting the selected client
                refreshClients();
                refreshUsers(client);

                setLoading(false);
            },
            response => {
                setErrorMessage(data.t.read("error_during_save"));
                setLoading(false);
            });
    };

    const cancelSubmitChanges = () => {
        setShowSubmitChangesConfirm(false);
    };


    const addUser = () => {
        // Check if all required fields are filled
        if (!newUser.email || !newUser.first_name || !newUser.last_name || !newUser.role) {
            setErrorMessage(data.t.read("fill_all_fields_before_adding_user"));
            return;
        }
        setShowAddUserConfirm(true);
    };

    const confirmAddUser = () => {
        // Validate required fields
        if (!newUser.email || !newUser.first_name || !newUser.last_name || !newUser.role) {
            setShowAddUserModal(false);
            setErrorMessage(data.t.read("fill_all_fields_before_adding_user"));
            return;
        }

        // Add the new user to the users list
        setUsers(users => [
            ...users,
            {
                id_client: client._id,
                email: newUser.email,
                active: true,
                password: "",
                first_name: newUser.first_name,
                last_name: newUser.last_name,
                unit: "ALL",
                role: newUser.role,
            }
        ]);

        // Close the modal
        setShowAddUserModal(false);

        // Reset newUser fields
        setNewUser({
            email: "",
            first_name: "",
            last_name: "",
            role: newUserAvailableRoles()[0] ?? "Admin",
        });
    };

    const cancelAddUser = () => {
        setShowAddUserModal(false);
        // Reset newUser fields
        setNewUser({
            email: "",
            first_name: "",
            last_name: "",
            role: newUserAvailableRoles()[0] ?? "Admin",
        });
    };

    const updateNewUser = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const newUserAvailableRoles = () =>{
        if(client === null) return []
        let roles = client.config.roles
        let returnRoles = [];

        // if SuperAdmin, only Admin is available
        if (data.role === "SuperAdmin"){
            returnRoles.push("Admin")
        }
        // if Admin, all roles equal or below Admin are available
        else if(data.role === "Admin"){
            for(let i = roles.indexOf("Admin"); i < roles.length; i++){
                returnRoles.push(roles[i]);
            }
        }
        return returnRoles;
    }

    const openAddUserModal = () => {
        setShowAddUserModal(true);
        // Optionally reset newUser fields
        setNewUser({
            email: "",
            first_name: "",
            last_name: "",
            role: newUserAvailableRoles()[0] ?? "Admin",
        });
    };

    const capitalizeFirstLetter = (e) => {
        const { name, value } = e.target;
        const capitalizedValue = value
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

        return { target: { name, value: capitalizedValue } };
    };

    const cancelChanges = () => {
        if (isDirty) {
            const confirmDiscard = window.confirm(data.t.read("unsaved_changes_discard_confirmation"));
            if (!confirmDiscard) return;
        }
        refreshUsers(client);
    }


    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const getFilteredUsers = () => {
        const searchTerm = filterValue.toLowerCase();
        return users.filter(user => {
            const emailMatch = user.email ? user.email.toLowerCase().includes(searchTerm) : false;
            const firstNameMatch = user.first_name ? user.first_name.toLowerCase().includes(searchTerm) : false;
            const lastNameMatch = user.last_name ? user.last_name.toLowerCase().includes(searchTerm) : false;
            return emailMatch || firstNameMatch || lastNameMatch;
        });
    };

    //pagination logic
    const filteredUsers = getFilteredUsers();
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + pageSize - 1;
    const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem + 1);
    const totalPages = Math.ceil(filteredUsers.length / pageSize);
    const emptyRowsCount =  pageSize - currentUsers.length;
    const emptyRows = Array(emptyRowsCount).fill({});

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    return (
        !loading ?
            <div>
                <div>
                    <div>
                        <div className="card-title d-flex justify-content-left">
                            <h2>{data.t.read("user_management")}</h2>
                        </div>
                        <div className='mb-2'>


                            <div className="card p-1 mt-2 rounded-lg">
                                <div className="d-flex justify-content-between align-items-center px-4 py-2">
                                    {data.role === "SuperAdmin" ? (
                                            <div className="flex mr-3">
                                            <Select
                                                    name="client"
                                                    options={clients.map(client => ({value: client, label: client.name}))}
                                                    styles={styleSelect}
                                                    value={client === null ? null : {value: client, label: client.name}}
                                                    classNamePrefix="select"
                                                    onChange={(choice) => {
                                                        selectClient(choice.value);
                                                    }}
                                                />
                                            </div>
                                        )
                                        :
                                        client ? (// Display the client name for Admins
                                            <div>{client.name}</div>
                                        ) : null
                                    }
                                    {/*<h4 className="mt-4 mb-3">{data.t.read("user_list")} </h4>*/}
                                    <div
                                        className="d-flex"
                                    >
                                            <input
                                                type="text"
                                                placeholder={data.t.read("filter_by_email_name")}
                                                className="form-control search-field w-100"
                                                value={filterValue}
                                                onChange={(e) => setFilterValue(e.target.value)}
                                            />
                                        {/*</div>*/}
                                        <button
                                            className='btn btn-info btn-sm ml-2'
                                            type="button" disabled={client === null}
                                            onClick={openAddUserModal}
                                            >
                                            {data.t.read("add")}
                                        </button>
                                    </div>
                                </div>

                                <table className='table font-small'>
                                    {/*<table className="table table-hover col-10 m-auto  table-striped">*/}

                                        <thead>
                                        <tr>
                                            <th className="text-center">{data.t.read("gl_category.Assets")}</th>
                                            <th className="text-center">{data.t.read("rule.email")}</th>
                                            <th className="text-center">{data.t.read("client_table.new_password")}</th>
                                            <th className="text-center">{data.t.read("first_name")}</th>
                                            <th className="text-center">{data.t.read("last_name")}</th>
                                            <th className="text-center">{data.t.read("client_table.role")}</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            currentUsers.map((user, index) => {
                                                return <tr  hidden={user?.is_deleted} key={user._id}>
                                                    <td>
                                                        <div className="d-flex justify-content-center">
                                                            <Switch
                                                                className='switch mt-0'
                                                                checked={user.active}
                                                                disabled={!userCanModify(user)}
                                                                width={70}
                                                                onColor="#F39800"
                                                                onHandleColor="#2693e6"
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                onChange={(active) => updateUser({...user, active})}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input disabled={!userCanModify(user)}
                                                               required={true && !user?.is_deleted}
                                                               placeholder={data.t.read("rule.email")}
                                                               className='form-control form-control-sm w-100 mx-auto'
                                                               type="email" value={user.email ? user.email : ""}
                                                               onChange={e => updateUser({
                                                                   ...user,
                                                                   email: e.target.value
                                                               })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-sm btn-primary d-block mx-auto"
                                                                disabled={!userCanModify(user) | user.is_generating | !user._id}
                                                                onClick={() => generatePassword(user)}>
                                                            <FontAwesomeIcon
                                                                className={user.is_generating ? "mr-2 infinite-rotate" : "mr-2"}
                                                                icon={faCog}/>
                                                            <span>MdP</span>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <input
                                                            disabled={!userCanModify(user)}
                                                            required={!user?.is_deleted}
                                                            placeholder={data.t.read("first_name")}
                                                            className='form-control form-control-sm w-75 mx-auto'
                                                            type="text" value={user.first_name}
                                                            onChange={e => updateUser({
                                                                ...user,
                                                                first_name: e.target.value
                                                            })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            disabled={!userCanModify(user)}
                                                            required={!user?.is_deleted}
                                                            placeholder={data.t.read("last_name")}
                                                            className='form-control form-control-sm w-75 mx-auto'
                                                            type="text" value={user.last_name}
                                                            onChange={e => updateUser({
                                                                ...user,
                                                                last_name: e.target.value
                                                            })}
                                                        />

                                                    </td>
                                                    <td>
                                                        <select disabled={!userCanModify(user)} value={user.role}
                                                                onChange={e => updateUser({
                                                                    ...user,
                                                                    role: e.target.value
                                                                })}
                                                                className='form-control form-control-sm test mx-auto w-75'>
                                                            {
                                                                availableRoles(user).map(role => {
                                                                    return <option key={role} value={role}
                                                                                   disabled={role == "SuperAdmin"}>{role}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </td>

                                                    <td>
                                                        <button
                                                            disabled={!userCanModify(user)}
                                                            type="button"
                                                            className="btn btn-sm btn-link mx-auto w-75"
                                                            onClick={() => confirmDeleteUser(user)}>
                                                            <FontAwesomeIcon className="text-danger"
                                                                             icon={faWindowClose}/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            emptyRows.map((_, idx) => (
                                                <tr key={`empty-${idx}`}>
                                                    <td colSpan="7">&nbsp;</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                    {
                                        users.length === 0 ? (
                                            client === null ? (
                                                // If no client is selected, prompt to select a client
                                                <span
                                                    className='font-italic'>&emsp; {data.t.read("select_client_to_see_users")}</span>
                                            ) : (
                                                // If client is selected but no users found, display "no_users_found"
                                                <span
                                                    className='font-italic'>&emsp; {data.t.read("no_users_found")}</span>
                                            )
                                        ) : (
                                            // If users are found, display the rest of the content
                                            <div className='d-flex justify-content-between p-2'>
                                                {/* Save and Cancel Buttons */}
                                                <div>
                                                    <button
                                                        type="button"
                                                        onClick={submitChanges}
                                                        className="btn btn-primary">{data.t.read("save")}
                                                    </button>
                                                    &emsp;
                                                    <button
                                                        type="button"
                                                        onClick={cancelChanges}
                                                        className="btn btn-outline-dark">{data.t.read("cancel")}
                                                    </button>
                                                </div>

                                                {/* Pagination Information */}
                                                <div>
                                                <span className='font-italic'>
                                                    {indexOfFirstItem + 1}-{Math.min(indexOfLastItem + 1, filteredUsers.length)} {data.t.read("rule.of")}
                                                </span>
                                                    <span className='p-1 text-primary'> {filteredUsers.length} </span>
                                                </div>

                                                {/* Pagination Buttons */}
                                                <div>
                                                    <button className='btn btn-sm' disabled={page === 1} type="button"
                                                            onClick={() => handlePageChange(1)}>
                                                        <FontAwesomeIcon className='fa-xs' icon={faAngleDoubleLeft}/>
                                                    </button>
                                                    <button className='btn btn-sm' disabled={page === 1} type="button"
                                                            onClick={() => handlePageChange(page - 1)}>
                                                        <FontAwesomeIcon className='fa-xs' icon={faChevronLeft}/>
                                                    </button>
                                                    <span className='active text-primary'>
                                                    <span className='p-2'>{page}</span>
                                                </span>
                                                    <button className='btn btn-sm' type="button"
                                                            disabled={page >= totalPages}
                                                            onClick={() => handlePageChange(page + 1)}>
                                                        <FontAwesomeIcon className='fa-xs' icon={faChevronRight}/>
                                                    </button>
                                                    <button className='btn btn-sm' type="button"
                                                            disabled={page >= totalPages}
                                                            onClick={() => handlePageChange(totalPages)}>
                                                        <FontAwesomeIcon className='fa-xs' icon={faAngleDoubleRight}/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }
                            </div>
                        </div>
                    </div>
                </div>
                {showAddUserConfirm && (
                    <Modal
                        title="Confirm Add User"
                        okAction={confirmAddUser}
                        cancelAction={cancelAddUser}
                        okText="Confirm"
                        cancelText="Cancel"
                    >
                        <p>Please confirm the addition of the following user:</p>
                        <ul>
                            <li><strong>Email:</strong> {newUser.email}</li>
                            <li><strong>First Name:</strong> {newUser.first_name}</li>
                            <li><strong>Last Name:</strong> {newUser.last_name}</li>
                            <li><strong>Role:</strong> {newUser.role}</li>
                        </ul>
                    </Modal>
                )}
                {showDeleteUserConfirm && (
                    <Modal
                        title={`Delete ${userToDelete?.first_name} ${userToDelete?.last_name}?`}
                        okAction={deleteUser}
                        cancelAction={cancelDeleteUser}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <p className='text-danger'>{data.t.read("confirm_delete_user")}</p>
                    </Modal>
                )}
                {
                    errorMessage && (
                        <Modal title="Attention" okText={"OK"} okAction={() => setErrorMessage(null)}>
                            <p style={{ whiteSpace: 'pre-line' }} className='text-danger'>{errorMessage}</p>
                        </Modal>)
                }
                {
                    successMessage && (
                        <Modal title="Attention" okText={"OK"} okAction={() => setSuccessMessage(null)}>
                            {successMessage}
                        </Modal>

                    )
                }
                {showSubmitChangesConfirm && (
                    <Modal
                        title="Confirm Save Changes"
                        okAction={confirmSubmitChanges}
                        cancelAction={cancelSubmitChanges}
                        okText="Save"
                        cancelText="Cancel"
                    >
                        <p>{data.t.read("confirm_save_changes")}</p>
                    </Modal>
                )}
                {showResetPasswordConfirm && (
                    <Modal
                        title="Confirm Password Reset"
                        okAction={confirmResetPassword}
                        cancelAction={cancelResetPassword}
                        okText="Reset Password"
                        cancelText="Cancel"
                    >
                        <p>
                            {data.t.read("confirm_reset_password_for")}{" "}
                            <strong>
                                {userToResetPassword?.first_name} {userToResetPassword?.last_name}
                            </strong>
                            ?
                        </p>
                    </Modal>
                )}
                {showAddUserModal && (
                    <Modal
                        title={data.t.read("new_user")}
                        okAction={confirmAddUser}
                        cancelAction={cancelAddUser}
                        okText={data.t.read("add_user")}
                        cancelText="Cancel"
                    >
                        {/* Form fields for new user */}
                        <div>
                            <div className="form-group">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={newUser.email}
                                    onChange={updateNewUser}
                                    required
                                    placeholder={data.t.read("email")}
                                />
                            </div>
                            <div className="form-group">
                                <label>First Name:</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="form-control"
                                    value={newUser.first_name}
                                    onChange={(e) => updateNewUser(capitalizeFirstLetter(e))}
                                    required
                                    placeholder={data.t.read("first_name")}
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name:</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    className="form-control"
                                    value={newUser.last_name}
                                    onChange={(e) => updateNewUser(capitalizeFirstLetter(e))}
                                    required
                                    placeholder={data.t.read("last_name")}
                                />
                            </div>
                            <div className="form-group">
                                <label>Role:</label>
                                <select
                                    name="role"
                                    className="form-control"
                                    value={newUser.role}
                                    onChange={updateNewUser}
                                    required
                                >
                                    {newUserAvailableRoles().map(role => (
                                        <option key={role} value={role}>{role}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
            :
            <div className="d-flex loading-container">
                <FontAwesomeIcon className="infinite-rotate" icon={faSpinner}/>
            </div>

    )
}

export default inject('data')(withRouter(observer(AdminUsers)));